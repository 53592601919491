/* AssistantForm.css */

/* Styling for the entire assistant form container */
.assistant-form {
    position: fixed;
    bottom: 86px;
    left: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 999;
    transition: transform 0.3s ease-in-out;
  }
  
  /* Styling for the assistant icon */
  .assistant-icon {
    background-color: #14532d;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }
  
  .assistant-icon:hover {
    background-color: #14532d;
  }
  
  /* Styling for the close button icon */
  .assistant-icon img {
    width: 30px;
    height: 30px;
  }
  
  /* Styling for the form container when it's open */
  .form-container {
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    padding: 20px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;

    max-width: 300px; /* Adjust the value to your preferred maximum width */

  }
  
  /* Styling for the form container title */
  .form-container h2 {
    font-size: 1rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  /* Styling for form input fields */
  .form-container input,
  .form-container textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
    font-size: 1rem;
  }
  
  /* Styling for the submit button */
  .form-container button {
    background-color: #14532d;
    color: #fff;
    border: none;
    border-radius: 3px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }
  
  .form-container button:hover {
    background-color: #14532d;
  }
  