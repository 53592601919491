@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;1,400&family=Poppins:wght@200;300;400;600&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
