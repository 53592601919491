@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');
.each-slide-effects-ban > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: calc(100vh - 30px);
    font-family: 'Great Vibes', cursive;

  }

  .each-slide-effect span {
    padding: 20px;
    font-size: 20px;
    background: #efefef;

    text-align: center;
    color: #ffffff;
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the alpha value to control the darkness of the overlay */
  }

  .static-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #ffffff;
    width: 100%;
    z-index: 999; /* Ensure the text appears above the slides */
  }
  
  .static-text p{
    /* text-transform:uppercase; */
    font-size: 55px;
    /* font-family: "Montserrat",sans-serif; */
    font-family: 'Great Vibes', cursive;
  }

  
  