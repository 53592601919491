@import url('https://fonts.googleapis.com/css2?family=Gabriela&display=swap');
.each-slide-effect > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 650px;
  }

  .testimonial-text p{
    font-family: 'Gabriela', serif;  }
  
